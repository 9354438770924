import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { supabase } from "../utils/supabase";

function Edit() {
  let { taskId } = useParams();
  const [name, setName] = useState("");
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(null);
  const [gloablError, setGlobalError] = useState(false);
  const [success, setSuccess] = useState("");

  const getTodo = async () => {
    let { data: todos, error } = await supabase
      .from("todos")
      .select("*")
      .eq("id", taskId)
      .limit(1)
      .single();

    if (error) {
      return setGlobalError(true);
    }

    setName(todos?.task);
    setCompleted(todos?.is_complete);
  };
  useEffect(() => {
    getTodo();
  });

  const submit = async (event) => {
    event.preventDefault();

    if (name === "") {
      return setError("Please Enter Todo");
    }
    const { error } = await supabase
      .from("todos")
      .update({ task: name, is_complete: completed })
      .eq("id", taskId)
      .select();
    if (error) {
      return setError(error);
    }
    setSuccess("Success, edited task");
    setError("");
  };

  if (gloablError) {
    return (
      <>
        <div className="container single-task-form">Task Not Found</div>
        <Link to={"/"} className="btn back-link">
          back to tasks
        </Link>
      </>
    );
  }

  if (name) {
    return (
      <div>
        <div className="container ">
          <form className="single-task-form">
            <h4>Edit Task</h4>
            <div className="form-control">
              <label>Task ID</label>
              <p className="task-edit-id">{taskId}</p>
            </div>
            <div className="form-control">
              <label htmlFor="name">Name</label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                name="name"
                required
                className="task-edit-name"
              />
            </div>
            <div className="form-control">
              <label htmlFor="completed">completed</label>
              <input
                checked={completed}
                onChange={(e) => setCompleted(!completed)}
                type="checkbox"
                name="completed"
                className="task-edit-completed"
              />
            </div>
            <button
              type="button"
              onClick={submit}
              className="block btn task-edit-btn"
            >
              edit
            </button>
            <div className="form-alert">{error}</div>
            <div className="form-row">{success}</div>
          </form>

          <Link to={"/"} className="btn back-link">
            back to tasks
          </Link>
        </div>
        {/* Add form or other elements for editing task details */}
      </div>
    );
  }
}

export default Edit;

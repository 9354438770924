import { Link, useRouteError } from "react-router-dom";
import Layout from "./layout";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <>
      <Layout />
      <div className="container">
        <div id="error-page">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>

          <Link to={"/"} className="btn back-link">
            back to tasks
          </Link>
        </div>
      </div>
    </>
  );
}

import { Link, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { supabase } from "../utils/supabase";
import { useNavigate } from "react-router-dom";

function Layout() {
  const [session, setSession] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  console.log(session);

  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    console.log(error);
    setSession(null);
    navigate("/");
    navigate(0);
  };

  return (
    <div>
      <nav>
        <Link to="/">Home</Link>
        <span style={{ float: "right" }}>
          {session ? (
            <>
              <span style={{ paddingRight: "20px" }}>{session.user.email}</span>
              <button type="button" onClick={logout}>
                Logout
              </button>
            </>
          ) : (
            <>
              <button type="button">
                <Link to="/login">Login</Link>
              </button>
            </>
          )}
        </span>
      </nav>
      <hr />

      <Outlet />
    </div>
  );
}

export default Layout;

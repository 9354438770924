import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { supabase } from "../utils/supabase";

function Task() {
  const [name, setName] = useState("");
  const [todos, setTodos] = useState([]);
  const [error, setError] = useState(null);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    getTasks();
  }, []); // Empty dependency array means this effect runs only once when component mounts

  const getTasks = async () => {
    let { data: todos, error } = await supabase
      .from("todos")
      .select("*")
      .order("inserted_at", { ascending: false });
    if (error) {
      setError(error);
    }
    setTodos(todos);
    setLoading(false);
  };

  const deleteTask = async (id) => {
    const { error } = await supabase.from("todos").delete().eq("id", id);
    if (error) {
      setError(error);
    }
    getTasks();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { error } = await supabase
      .from("todos")
      .insert([{ task: name, user_id: session.user.id }])
      .select();
    if (error) {
      setError(error);
    }
    getTasks();
    setName("");
  };

  return (
    <>
      <form className="task-form" onSubmit={handleSubmit}>
        <h4>task manager</h4>
        <div className="form-control">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            name="name"
            className="task-input"
            placeholder="e.g. Do Exercise"
          />
          <button type="submit" className="btn submit-btn">
            submit
          </button>
        </div>
        <div className="form-alert"></div>
      </form>
      <section className="tasks-container">
        <p className="loading-text">Loading...</p>
        <div className="tasks">
          {todos?.length > 0 &&
            todos.map((task) => (
              <div
                key={task.id}
                className={`single-task ${
                  task.is_complete ? "task-completed" : ""
                }`}
              >
                <h5>
                  <span>
                    <i className="far fa-check-circle"></i>
                  </span>
                  {task.task}
                </h5>
                <div className="task-links">
                  <Link to={`/edit/${task.id}`} className="edit-link">
                    <i className="fas fa-edit"></i>{" "}
                  </Link>

                  <button
                    type="button"
                    onClick={() => deleteTask(task.id)}
                    className="delete-btn"
                    data-id={task.id}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            ))}

          {error && <div>Error: {error.message}</div>}

          {loading && <div>Loading...</div>}

          {!session && (
            <>
              <div style={{ margin: "0 auto" }}>
                Please Login to see your Tasks.{" "}
              </div>
              <Link to={"/login"} className="btn back-link">
                Login
              </Link>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default Task;

import Task from "../components/Task";

function App() {
  return (
    <>
      <Task />
    </>
  );
}
export default App;

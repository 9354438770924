import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./routes/app";
import ErrorPage from "./routes/error-page";
import Edit from "./routes/edit";
import Login from "./routes/login";
import Layout from "./routes/layout";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "edit/:taskId",
        element: <Edit />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
